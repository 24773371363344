import type { PopoverProps } from "@radix-ui/react-popover";
import { Button, Checkbox, cn, Label, Popover, PopoverContent, PopoverTrigger } from "@somewear/ui";
import { ListFilter } from "lucide-react";
import * as React from "react";
import { useCallback } from "react";

import { SomewearDataTableHeaderIcon } from "./SomewearDataTableHeaderIcon";

type OptionId = string;

interface IProps<T extends { id: OptionId } | string>
	extends PopoverProps,
		React.PropsWithChildren {
	options: T[];
	selected: OptionId[];
	onChange: (newSelectedOptions: OptionId[] | undefined) => void;
	onSelectAll: () => void;
	className?: string;
	renderOption?: (option: T) => React.ReactNode;
}

export function FilterDropdown<T extends { id: OptionId } | string>({
	options,
	selected,
	onChange,
	onSelectAll,
	className,
	children,
	renderOption = (option) => (typeof option === "string" ? option : option.id),
	...popoverProps
}: IProps<T>) {
	const isFiltered = selected.length > 0;

	const getOptionId = useCallback((option: T) => {
		return typeof option === "string" ? option : option.id;
	}, []);

	const onSelect = useCallback(
		(optionId: OptionId) => {
			const newSelectedOptions = selected.includes(optionId)
				? selected.filter((id) => id !== optionId)
				: [...selected, optionId];

			onChange(newSelectedOptions.length > 0 ? newSelectedOptions : undefined);
		},
		[selected, onChange],
	);

	return (
		<Popover {...popoverProps}>
			<PopoverTrigger asChild>
				{children ?? (
					<SomewearDataTableHeaderIcon isActive={isFiltered}>
						{isFiltered ? selected.length : <ListFilter className="size-3.5" />}
					</SomewearDataTableHeaderIcon>
				)}
			</PopoverTrigger>

			<PopoverContent>
				<div className={cn("flex-stack gap-4 max-h-[400px] overflow-y-scroll", className)}>
					<div className="text-xs text-gray-500">Select Filters</div>

					<div className="space-y-3">
						{options.filter(Boolean).map((option) => (
							<div key={getOptionId(option)} className="flex items-center space-x-2">
								<Checkbox
									id={getOptionId(option)}
									checked={selected.includes(getOptionId(option))}
									onCheckedChange={() => onSelect(getOptionId(option))}
								/>
								<Label
									htmlFor={getOptionId(option)}
									className="flex-grow cursor-pointer max-w-full truncate font-medium"
								>
									{renderOption(option)}
								</Label>
							</div>
						))}
					</div>

					<div className="flex justify-between items-center">
						<Button
							variant="lightSecondary"
							onClick={(e) => {
								e.stopPropagation();
								onSelectAll();
							}}
							className="text-xs px-8 h-7"
						>
							Show All
						</Button>
					</div>
				</div>
			</PopoverContent>
		</Popover>
	);
}
