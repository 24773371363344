import { TableContainer as MuiTableContainer } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

import { RenderIf } from "../../common/RenderIf";
import { cn } from "../../lib/utils";

interface IEmptySearchProps {
	label?: string;
	onClearQuery: () => void;
	hideClearSearchQuery?: boolean;
}

export const SomewearTableEmptySearch = (props: IEmptySearchProps) => {
	return (
		<EmptySearch>
			<div>{props.label ?? "No results found"}</div>

			<RenderIf condition={!props.hideClearSearchQuery}>
				<div>
					Change or{" "}
					<span className="link" onClick={() => props.onClearQuery()}>
						clear search criteria
					</span>
				</div>
			</RenderIf>
		</EmptySearch>
	);
};

const EmptySearch = styled.div`
	padding: 4rem 2rem;
	color: #74797e;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	text-align: center;
	width: 100%;

	.link {
		color: var(--somewear-blue);
		cursor: pointer;

		&:hover {
			color: var(--somewear-blue);
		}
	}
`;

interface SomewearTableFooterProps extends React.PropsWithChildren {
	className?: string;
	hasChanges?: boolean;
	onDiscardChanges?: () => void;
	onApplyChanges?: () => void;
}

export const SomewearTableFooter: React.FC<SomewearTableFooterProps> = ({
	className,
	children,
}) => {
	return (
		<footer
			className={cn(
				"bg-muted border border-border flex justify-between items-center font-medium rounded-b-lg p-5 h-12 text-xs w-full",
				className,
			)}
		>
			<div className="flex items-center flex-1">{children}</div>
		</footer>
	);
};

export const SomewearTableContainer = styled(MuiTableContainer)`
	background-color: var(--somewear-white);

	.MuiTableCell-root {
		padding: 0.25rem 0.5rem;
		font-size: 1rem;
		font-family: var(--somewear-font);
		border-color: var(--somewear-border);
	}

	.device-usage {
		font-size: 13px;
	}

	.device-usage-button {
		.MuiButton-label {
			min-width: 0;
		}
	}

	.MuiTableHead-root {
		position: sticky;
		z-index: 4;
		top: 0;
		background: #fff;
		height: 40px;
	}

	&.organization {
		.MuiTableHead-root {
			top: 0;
		}
	}

	.table-wrapper {
		position: relative;
		overflow-x: auto;
		overflow-x: overlay;

		overflow-y: auto;
		overflow-y: overlay;
		border: 1px solid var(--somewear-border);

		&::-webkit-scrollbar {
			width: 0.5rem;
			height: 0.5rem;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #ededed;
			border-radius: 0.25rem;
		}
	}

	table {
		border-collapse: separate;

		thead {
			th.MuiTableCell-root {
				// padding-left: 1.5rem;
				padding-left: 1rem;
				font-size: 0.75rem;
				font-weight: 700;
			}

			tr:first-child:not(:last-child) {
				th {
					color: #a1a1a1;
					height: 40px;
					font-size: 0.875rem;
					font-weight: 700;
					text-transform: uppercase;

					&.group-details,
					&.group-settings {
						background: #fcfcfc;
					}

					&:first-child {
						position: sticky;
						left: 0;
						background: #fff;
						z-index: 2;
						padding-right: 0;
						border-right: 1px solid var(--somewear-border);
					}
				}
			}

			tr:last-child {
				border-left: 0;
				border-right: 1px solid var(--somewear-border);

				th {
					color: #74797e;
					padding-right: 3rem;
					font-size: 0.875rem;
					font-weight: 500;

					&:nth-child(1) {
						padding-right: 0;
					}
				}
			}

			th {
				text-wrap: nowrap;
			}
		}

		td.col-select,
		th.col-select {
			padding-left: 4rem !important;

			.MuiCheckbox-root {
				position: absolute;
				// left: 1.5rem;
				left: 0.5rem;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		tbody {
			tr {
				border-left: 1px solid var(--somewear-border);
				border-right: 1px solid var(--somewear-border);

				&.sticky-actions {
					td:last-child {
						position: sticky;
						right: 0;
						z-index: 2;
						background-color: white;
						padding-right: 0;
						padding-left: 0;
					}
				}

				&.group-row {
					position: relative;

					&.group-row-with-changes > td:first-child {
						&:after {
							content: "";
							position: absolute;
							border-radius: 100%;
							left: 7.5px;
							top: 50%;
							transform: translateY(-50%);
							width: 5px;
							height: 5px;
							background: #3569de;
							z-index: 10;
						}
					}
				}

				td.MuiTableCell-body {
					font-size: 0.875rem;
					// padding-left: 1.5rem;
					padding-left: 1rem;
					padding-right: 3rem;

					&.changed {
						background: #e4f3ff;
					}

					&.group-summary {
						padding-right: 0;
					}

					&.col-imei {
						color: #7a8085;

						.device-model {
							font-size: 0.75rem;
							font-weight: 700;
							text-transform: uppercase;
						}

						.device-imei {
							font-size: 0.75rem;
							color: #1b1b1b;
						}
					}
				}

				&:last-child {
					td {
						border-bottom: none;
					}
				}
			}
		}

		thead {
			tr {
				&:last-child {
					th {
						&:first-child {
							position: sticky;
							left: 0;
							background: #fff;
							z-index: 2;
							border-right: 1px solid var(--somewear-border);
						}
					}
				}
			}
		}

		tbody {
			tr {
				height: 48px;

				td {
					&:first-child {
						position: sticky;
						left: 0;
						background-color: #fff;
						z-index: 2;
						border-right: 1px solid var(--somewear-border);
					}
				}

				&:first-child:not(.has-changes) {
					background-color: #fff;
				}
			}

			.has-changes {
				background-color: #e4f3ff !important;
			}
		}

		tr {
			.group-details {
				& ~ .group-details {
					border-left: none;
				}
			}

			.group-assign {
				border-left: 1px solid var(--somewear-border);

				& ~ .group-assign {
					border-left: none;
				}
			}

			.group-settings {
				border-left: 1px solid var(--somewear-border);

				& ~ .group-settings {
					border-left: none;
				}
			}

			.group-status {
				border-left: 1px solid var(--somewear-border);

				& ~ .group-status {
					border-left: none;
				}
			}
		}

		.col-imei {
			max-width: 180px;
		}

		.user-actions {
			width: 40px;
		}

		.asset-picker {
			margin-left: -0.75rem;
		}

		.device-settings-checkbox {
			fill: #3c3c3c;
		}

		.tracking-interval {
			.select-icon svg {
				fill: #a6a6a6;
			}
		}

		.workspace-picker {
			margin-left: -1rem;
			flex-grow: 1;
			max-width: 200px;

			.styled-select {
				border: none;
				background: none;

				.MuiSelect-select {
					background: none;
				}
			}

			.MuiSelect-icon {
				color: #a6a6a6;
			}
		}
	}

	@media (max-width: 768px) {
		// max-height: 90%;

		table thead tr:first-child th {
			// display: none;
		}

		table thead tr:first-child th:first-child {
			position: relative;
		}

		table thead tr:last-child th:first-child {
			position: relative;
		}

		// table thead tr:last-child th:nth-child(2) {
		// 	position: relative;
		// 	left: 0;
		// }

		table tbody tr td:first-child {
			position: relative;
		}

		// table tbody tr td:nth-child(2) {
		// 	position: relative;
		// 	left: 0;
		// }

		tr:first-child:not(:last-child) th:first-child {
			&:first-child {
				position: relative;
			}
		}
	}
`;
