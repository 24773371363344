import { useLayoutEffect, useRef } from "react";

export function useScrollToTop(deps?: React.DependencyList) {
	const scrollRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		const frameId = requestAnimationFrame(() => {
			if (scrollRef.current) {
				scrollRef.current.scrollTop = 0;
			}
		});

		return () => cancelAnimationFrame(frameId);
	}, deps);

	return {
		scrollRef,
	};
}
