import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";

const buttonVariants = cva(
	"inline-flex items-center gap-2 justify-center whitespace-nowrap text-sm font-medium ring-offset-background rounded-md transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:bg-gray-200 disabled:text-gray-500 cursor-pointer max-h-full",
	{
		variants: {
			variant: {
				default:
					"bg-primary text-primary-foreground hover:bg-primary/90 dark:bg-stealth-button dark:hover:bg-stealth-button/90 dark:border dark:border-stealth-divider dark:disabled:bg-stealth-button/50",
				destructive:
					"bg-destructive text-destructive-foreground disabled:bg-destructive/50 disabled:text-destructive-foreground hover:bg-destructive/90",
				outline:
					"border border-primary bg-transparent dark:border-white dark:hover:bg-white/5 dark:hover:text-white hover:bg-muted hover:text-muted-foreground",
				lightOutline:
					"border border-border bg-transparent dark:border-white dark:hover:bg-white/5 dark:hover:text-white hover:bg-accent hover:text-accent-foreground",
				secondary:
					"bg-secondary text-secondary-foreground dark:border dark:border-stealth-divider hover:bg-secondary/80",
				lightSecondary: "bg-secondary/15 text-secondary hover:bg-secondary/25",
				ghost: "hover:bg-muted hover:text-muted-foreground disabled:bg-transparent dark:hover:bg-stealth-gradient-blue dark:hover:text-foreground",
				ghostSecondary: "hover:bg-muted text-secondary",
				ghostDestructive: "text-destructive hover:bg-destructive/5",
				link: "text-primary underline-offset-4 hover:opacity-90",
				linkSecondary: "text-secondary underline-offset-4 hover:opacity-90",
				white: "bg-white text-primary hover:bg-white/90",
			},
			size: {
				default: "h-10 px-6 py-2",
				xs: "h-8 px-4",
				sm: "h-9 px-3",
				lg: "h-11 px-8",
				icon: "h-8 w-8 rounded-full",
			},
			shape: {
				default: "",
				rounded: "rounded-full",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "sm",
			shape: "default",
		},
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	tooltip?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, shape, asChild = false, tooltip, ...props }, ref) => {
		const Comp = asChild ? Slot : "button";
		const buttonComponent = (
			<Comp
				className={cn(buttonVariants({ variant, size, shape, className }))}
				ref={ref}
				{...props}
			/>
		);

		if (tooltip) {
			return (
				<TooltipProvider>
					<Tooltip delayDuration={100}>
						<TooltipTrigger asChild>{buttonComponent}</TooltipTrigger>
						<TooltipContent>
							<p>{tooltip}</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			);
		}

		return buttonComponent;
	},
);
Button.displayName = "Button";

export { Button, buttonVariants };
