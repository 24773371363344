import type { ColumnDef, FilterFn, Table } from "@tanstack/react-table";

import type { SomewearColumn, SomewearDataTableColumns } from "./SomewearDataTable.types";

export function createMultiSelect<T>(): FilterFn<T> {
	return (row, columnId, filterValues) => {
		if (!filterValues || filterValues.length === 0) {
			return true;
		}

		const rowValue = row.getValue(columnId);

		return typeof rowValue === "string" && filterValues.includes(rowValue);
	};
}

export function deriveDataTableColumns<T>(
	config: SomewearDataTableColumns<T>,
	filterFn?: FilterFn<T>,
) {
	const values = Object.entries(config) as [keyof T, SomewearColumn<T, unknown>][];

	return values.reduce(
		(acc, [key, value]) => {
			const col: ColumnDef<T, T[keyof T]> = {
				...(typeof value.accessor === "string"
					? { accessorKey: value.accessor as keyof T }
					: {}),
				...(typeof value.accessor === "function" ? { accessorFn: value.accessor } : {}),
				id: key as string,
				header: value.table?.header,
				cell: value.table?.cell,
				sortingFn: value.sort,
				sortDescFirst: value.sortDescFirst,
				invertSorting: value.invertSorting,
				sortUndefined: value.sortUndefined,
				enableGlobalFilter: value.searchable,
				enableColumnFilter: value.filterable,
				enableSorting: Boolean(value.sort),
				filterFn: value.filterable ? filterFn : undefined,
				size: value.table?.size,
				minSize: value.table?.minSize,
				maxSize: value.table?.maxSize,
				meta: {
					list: value.list,
					defer: value.table?.defer,
				},
			};

			return { ...acc, [key]: col };
		},
		{} as Record<keyof T, ColumnDef<T, T[keyof T]>>,
	);
}

function getStartAndEnd(pageIndex: number, pageSize: number) {
	const start = pageIndex * pageSize + 1;
	const end = pageIndex * pageSize + pageSize;

	return { start, end };
}

export function getPaginationLabel<TData>(table: Table<TData>, entity: string = "") {
	const { start, end } = getStartAndEnd(
		table.getState().pagination.pageIndex,
		table.getState().pagination.pageSize,
	);
	const total = table.getFilteredRowModel().rows.length;

	if (total === 0) {
		return;
	}

	return `${start} - ${total > end ? end : total} of ${total} ${entity}`;
}
