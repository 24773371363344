import { cn, RenderIf, useScrollToTop } from "@somewear/ui";
import type { Table } from "@tanstack/react-table";
import * as React from "react";
import styled from "styled-components";

import { SomewearDataTableCell } from "./SomewearDataTableCell";
import { SomewearDataTableHeader } from "./SomewearDataTableHeader";
import { SomewearTableEmptySearch, SomewearTableFooter } from "./SomewearTable";

interface IProps<T> extends React.PropsWithChildren, React.HTMLAttributes<HTMLDivElement> {
	table: Table<T>;
	data: T[];
	noDataAvailableMessage?: string;
	onRowClick?: (data: T) => void;
	readonly?: boolean;
	footerClassName?: string;
}

export function SomewearDataTable<T>({
	data,
	table,
	noDataAvailableMessage,
	children,
	className,
	onRowClick,
	readonly,
	...props
}: IProps<T>) {
	const noDataAvailable = data.length === 0;
	const isRowClickable = Boolean(onRowClick);
	const isPaginated = Boolean(table.options.meta?.isPaginated);
	const { scrollRef } = useScrollToTop([
		table.getState().pagination?.pageIndex,
		table.getState().sorting,
	]);
	const { rows } = table.getRowModel();

	return (
		<SomewearTable
			className={cn(
				"overflow-hidden flex flex-col relative has-[footer]:pb-[calc(var(--data-table-row-height)_-_2px)]",
				{
					"[&_button[role='checkbox']]:hidden": readonly,
				},
				className,
			)}
			{...props}
		>
			<div
				ref={scrollRef}
				className={cn("table-wrapper border rounded h-full overflow-scroll")}
			>
				<table className={cn({ "border-b": Boolean(isPaginated) })}>
					<thead className="*:border-b">
						{table.getHeaderGroups().map((headerGroup) => {
							const headerGroupHasContent = headerGroup.headers.some(
								(header) => header.column.columnDef.header,
							);

							if (!headerGroupHasContent) return null;

							return (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<SomewearDataTableHeader key={header.id} header={header} />
									))}
								</tr>
							);
						})}
					</thead>

					<tbody>
						{rows.map((row) => (
							<tr
								className={cn({
									"cursor-pointer clickable": isRowClickable,
									"pointer-events-none [&_button[role='checkbox']]:hidden [&_button[role='switch']]:bg-gray-100 [&_button_svg]:hidden ":
										readonly,
								})}
								onClick={(e) => {
									if ((e.target as HTMLElement).closest('[role="dialog"]')) {
										return;
									}

									onRowClick?.(row.original);
								}}
								key={row.id}
							>
								{row.getVisibleCells().map((cell) => (
									<SomewearDataTableCell key={cell.id} cell={cell} />
								))}
							</tr>
						))}
					</tbody>
				</table>

				<RenderIf condition={table.getRowCount() === 0}>
					<SomewearTableEmptySearch
						hideClearSearchQuery={noDataAvailable}
						label={noDataAvailable ? noDataAvailableMessage : undefined}
						onClearQuery={() => {
							table.setGlobalFilter("");
							table.setColumnFilters([]);
						}}
					/>
				</RenderIf>
			</div>

			<RenderIf condition={Boolean(children)}>
				<SomewearTableFooter
					className={cn(
						"absolute left-0 bottom-0 z-10 w-full border-t",
						props.footerClassName,
					)}
				>
					{children}
				</SomewearTableFooter>
			</RenderIf>
		</SomewearTable>
	);
}

const SomewearTable = styled.div`
	width: 100%;
	overflow-x: auto;
	--data-table-font-size: 13px;
	--data-table-row-height: 48px;
	--data-table-header-height: 40px;

	table {
		min-width: 100%;
		table-layout: fixed;
		border-collapse: separate;
		border-spacing: 0;
		font-size: var(--data-table-font-size);
	}

	.table-wrapper {
		position: relative;
		overflow-x: auto;
		overflow-x: overlay;

		overflow-y: auto;
		overflow-y: overlay;
	}

	thead {
		position: sticky;
		z-index: 4;
		top: 0;
		background: #fff;

		tr {
			height: var(--data-table-header-height);
		}

		tr:first-child {
			background-color: hsl(var(--muted));
		}

		tr:first-child:not(:last-child) {
			th {
				color: #a1a1a1;
				font-size: var(--data-table-font-size);
				font-weight: 700;
				text-transform: uppercase;
				padding-left: 1rem;

				&:nth-child(even) {
					background: #fcfcfc;
				}

				&.is-pinned + th {
					border-left: none !important;
				}

				&:not(:first-child) {
					border-left: 1px solid hsl(var(--border));
				}
			}
		}

		tr:last-child {
			background: white;

			th {
				color: #74797e;
				font-size: var(--data-table-font-size);
				font-weight: 300;

				&:nth-child(1) {
					padding-right: 0;
				}
			}
		}
	}

	tr {
		height: var(--data-table-row-height);
		border-spacing: 0;
	}

	tr {
		th,
		td {
			padding: 0.25rem 0.5rem 0.25rem 0.5rem;
			font-size: var(--data-table-font-size);
			vertical-align: middle;
			border-bottom: 1px solid hsl(var(--border));
			white-space: nowrap;
			text-align: left;
			overflow: hidden;
			text-overflow: ellipsis;

			@media (min-width: 700px) {
				&.is-pinned {
					position: sticky;
					left: 0;
					z-index: 2;
					box-shadow: gray -4px 0px 1px -4px inset;
				}
			}

			&.is-pinned + .is-first-in-group {
				border-left: none !important;
			}

			&.is-first-in-group:not(:first-child) {
				border-left: 1px solid hsl(var(--border));
			}

			&.is-first-in-group {
				padding-left: 1rem;
			}

			&.is-last-in-group {
				padding-right: 1rem;
			}

			button {
				font-size: var(--data-table-font-size);
			}
		}
	}

	tr.clickable:hover {
		td {
			background-color: hsl(var(--somewear-gray-100) / 0.5) !important;
		}
	}

	th {
		font-size: var(--data-table-font-size);
		font-weight: 700;
		text-wrap: nowrap;
		vertical-align: middle;
	}

	tbody {
		tr:last-child {
			td {
				border-bottom: 0;
			}
		}
	}

	//
	.cell-secondary {
		font-size: 12px;
		margin-top: 2px;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: hsl(var(--somewear-gray-500));
		padding-right: 0.5rem;
	}

	.cell-heart-rate {
		font-weight: bold;
		font-size: 16px !important;
	}
`;
