import { ChevronDown, ChevronsUpDown, ChevronUp } from "lucide-react";
import { forwardRef, useMemo } from "react";
import * as React from "react";

import { Button } from "../../components/ui";
import { cn } from "../../lib/utils";

type SortDirection = "asc" | "desc" | false;

interface HeaderSortIconProps {
	sortDirection: SortDirection;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	isSorted: boolean;
}

export const HeaderSortIcon: React.FC<HeaderSortIconProps> = ({
	sortDirection,
	onClick,
	isSorted,
}) => {
	const SortIndicator = useMemo(() => {
		if (!sortDirection) return ChevronsUpDown;
		return sortDirection === "desc" ? ChevronDown : ChevronUp;
	}, [sortDirection]);

	return (
		<SomewearDataTableHeaderIcon
			isActive={isSorted}
			onClick={(e) => {
				e.stopPropagation();
				onClick?.(e);
			}}
		>
			<SortIndicator className="size-3.5" />
		</SomewearDataTableHeaderIcon>
	);
};

interface SomewearDataTableHeaderIconProps {
	isActive: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	children: React.ReactNode;
	className?: string;
}

export const SomewearDataTableHeaderIcon = forwardRef<
	HTMLButtonElement,
	SomewearDataTableHeaderIconProps
>(({ isActive, onClick, children, className }, ref) => {
	return (
		<Button
			ref={ref}
			variant="ghost"
			size="icon"
			className={cn(
				`ml-1 size-6 p-0 text-xs`,
				{
					"bg-blue-100 text-blue-600 hover:bg-blue-200 hover:text-blue-700": isActive,
					"hover:bg-muted": !isActive,
				},
				className,
			)}
			onClick={onClick}
		>
			{children}
		</Button>
	);
});

export default HeaderSortIcon;
