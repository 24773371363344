import type { Table } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import * as React from "react";
import styled from "styled-components";

import { RenderIf } from "../../common/RenderIf";
import { cn } from "../../lib/utils";
import { SomewearDataListHeader, SomewearDataListHeaderActions } from "./SomewearDataListHeader";

interface IProps<T> extends React.PropsWithChildren {
	className?: string;
	table: Table<T>;
	onClick?: (data: T) => void;
	noDataAvailableMessage?: string;
}

export function SomewearDataList<T>(props: IProps<T>) {
	const canInteract = Boolean(props.onClick);

	const sortableColumns = props.table
		.getHeaderGroups()
		.map((group) =>
			group.headers.filter((header) => Boolean(header.getContext().column.getCanSort())),
		)
		.flat();

	const filterableColumns = props.table
		.getHeaderGroups()
		.map((group) =>
			group.headers.filter((header) => Boolean(header.getContext().column.getCanFilter())),
		)
		.flat();

	return (
		<Component className={props.className}>
			<div className="somewear-data-list-headers">
				{sortableColumns.map((header) => (
					<SomewearDataListHeader
						key={`sort-${header.id}`}
						header={header}
						action={SomewearDataListHeaderActions.Sort}
					/>
				))}

				{filterableColumns.map((header) => (
					<SomewearDataListHeader
						key={`filter-${header.id}`}
						header={header}
						action={SomewearDataListHeaderActions.Filter}
					/>
				))}
			</div>

			<div className="somewear-data-list-container">
				<RenderIf condition={props.table.getRowCount() === 0}>
					<div className="somewear-data-list-no-results">
						{props.noDataAvailableMessage ?? "No Results"}
					</div>
				</RenderIf>

				{props.table.getRowModel().rows.map((row) => {
					const primaryField = row
						.getAllCells()
						.find(
							(cell) =>
								cell.column.id ===
								props.table.options.meta?.listConfig?.primaryField,
						);
					const dataFields = row
						.getAllCells()
						.filter((cell) =>
							props.table.options.meta?.listConfig?.dataFields.includes(
								cell.column.id as keyof T,
							),
						);
					const secondaryFields = row
						.getAllCells()
						.filter((cell) =>
							props.table.options.meta?.listConfig?.secondaryFields.includes(
								cell.column.id as keyof T,
							),
						);

					return (
						<SomewearDataCard
							className={cn({ "can-interact": canInteract }, "somewear-data-card")}
							onClick={() => props.onClick?.(row.original)}
							key={row.id}
						>
							<div className="somewear-data-card-primary">
								{primaryField?.getValue() as React.ReactNode}
							</div>

							<div className="somewear-data-card-fields">
								{dataFields.map((cell) => (
									<div key={cell.id} className="somewear-data-card-field">
										<div className="somewear-data-card-field-label">
											{cell.column.columnDef.meta?.list?.header as string}
										</div>
										<div className="somewear-data-card-field-value">
											{flexRender(
												cell.column.columnDef.meta?.list?.cell,
												cell.getContext(),
											)}
										</div>
									</div>
								))}
							</div>

							<div className="somewear-data-card-secondary">
								{secondaryFields.map((cell) => (
									<div
										key={cell.id}
										className="somewear-data-card-secondary-value"
									>
										{flexRender(
											cell.column.columnDef.meta?.list?.cell,
											cell.getContext(),
										)}
									</div>
								))}
							</div>
						</SomewearDataCard>
					);
				})}
			</div>
		</Component>
	);
}

const Component = styled.div`
	.somewear-data-list-container {
		padding: 0 1rem;
		position: relative;

		> * {
			margin-bottom: 1rem;
		}
	}

	.somewear-data-list-no-results {
		margin-top: 3rem;
		font-size: 14px;
		color: #797d81;
		text-align: center;
	}

	.somewear-data-list-headers {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		white-space: nowrap;
		overflow-x: auto;
		padding: 0 1rem 1rem;
		flex-shrink: 0;
	}

	.somewear-data-list-header {
		border: 1px solid var(--somewear-border);
		border-radius: 999px;
		padding: 0.25rem 1.1rem;
		font-size: 14px;
		background: white;
		cursor: pointer;
		display: flex;
		align-items: center;

		&.is-active {
			.header-icon.highlight-active {
				background: #797d81;
				color: white;
			}
		}

		&:hover,
		&.is-active {
			background: #475159;
			border: 1px solid #797d81;
			color: white;

			.header-icon {
				background: transparent;

				svg {
					fill: white;
				}
			}
		}

		.header-icon {
			position: relative;
			right: -8px;
			width: 20px;
			height: 20px;

			&:hover {
				background-color: transparent;
			}
		}
	}
`;

const SomewearDataCard = styled.div`
	padding: 1rem;
	border-radius: var(--somewear-border-radius);
	border: 1px solid var(--somewear-border);
	overflow: hidden;

	&.can-interact {
		cursor: pointer;

		&:hover {
			background: #f5f5f5;
		}
	}

	.somewear-data-card-primary {
		font-family: var(--somewear-font-title);
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 1.25rem;
	}

	.somewear-data-card-fields {
		display: flex;
		gap: 1rem;

		> *:not(:last-child) {
			border-right: 1px solid var(--somewear-border);
			padding-right: 1rem;
		}
	}

	.somewear-data-card-field-label {
		color: #797d81;
		font-size: 12px;
		font-weight: 200;
	}

	.somewear-data-card-field-value {
		font-size: 14px;
		margin-top: 0.5rem;
	}

	.somewear-data-card-secondary {
		display: flex;
		margin-top: 1.5rem;
		color: #797d81;

		> *:not(:last-child):after {
			content: "·";
			margin-left: 0.75rem;
			margin-right: 0.75rem;
		}
	}

	.somewear-data-card-secondary-value {
		font-style: italic;
		font-size: 12px;

		&:first-child,
		* {
			flex-shrink: 0;
		}
	}

	.cell-heart-rate {
		font-weight: bold;
	}
`;
