import {
	GetOrganizationIntegrationAccountsResponse,
	IdentityRecord,
	OrganizationRole,
} from "@somewear/api";
import { selectActiveIdentityId } from "@somewear/auth";
import { getDictionaryValue, identityIsIntegration } from "@somewear/model";
import { sortBy } from "lodash";
import { createSelector } from "reselect";

import { selectAllIdentities, selectIdentityById, selectIdentityEntities } from "./identitiesSlice";
import IntegrationIdentity = GetOrganizationIntegrationAccountsResponse.IntegrationIdentity;

export const selectActiveIdentity = createSelector(
	[selectIdentityEntities, selectActiveIdentityId],
	(identityDict, id) => getDictionaryValue(identityDict, id),
);

export const selectOrganizationRole = createSelector([selectActiveIdentity], (activeIdentity) => {
	return activeIdentity?.organizationRole ?? OrganizationRole.ORGANIZATIONROLENONE;
});

export const selectIsOrganizationAdmin = createSelector(
	[selectActiveIdentity],
	(activeIdentity) => {
		return activeIdentity?.organizationRole === OrganizationRole.ORGANIZATIONROLEADMIN;
	},
);

export const selectAllUserIdentitiesWithoutOrganizationSorted = createSelector(
	[selectAllIdentities],
	(contactIdentities) => {
		const filteredIdentities = contactIdentities.filter(
			(c) => !c.organizationId && c.type === IdentityRecord.Type.USER,
		);

		return sortBy(filteredIdentities, "fullName");
	},
);

export const selectIntegrationIdentityById = createSelector([selectIdentityById], (identity) => {
	return identity && identityIsIntegration(identity) ? identity : undefined;
});

export const selectIsIntegrationSitxBridgeAdapter = createSelector(
	[selectIntegrationIdentityById],
	(identity) => {
		return !!identity?.integrationData?.sitx;
	},
);
