import WarningIcon from "@mui/icons-material/Warning";
import type { OnCompleteHandler, Submitter } from "@somewear/model";
import * as React from "react";
import { createRef, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { RenderIf } from "../../common/RenderIf";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogMain,
	DialogTitle,
} from "../../components/ui/dialog";
import { cn } from "../../lib/utils";
import { PendingButton } from "../../notifications/PendingButton";
import type { ButtonProps } from "../ui/button";
import { Button } from "../ui/button";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type DialogProps = {
	open: boolean;
	onClose: OnCompleteHandler;
	completedBlock?: React.ReactNode;
	forceSubmitPending?: boolean;
	size?: "one-col" | "two-col"; // defaults to "one-col"
	icon?: React.ReactElement;
	children?: React.ReactNode;
	submitLabel?: string;
	secondarySubmitLabel?: string;
	beta?: boolean;
	nonDismissible?: boolean;
	submitDisabled?: boolean;
	informational?: boolean;
	title?: string;
	validate?: () => Promise<boolean>;
	className?: string;
	errorBlock?: ErrorBlock;
	footer?: React.ReactNode;
} & Optional<MapProp<ButtonProps, "variant", "primaryVariant">, "primaryVariant">;

type MapProp<T, K extends keyof T, N extends string> = {
	[P in N]: T[K];
};

export type SubmitProps<T = unknown, D = unknown> = DialogProps & {
	submitter: Submitter<T, D>;
	state?: D;
};

export type DualSubmitProps<T1 = unknown, T2 = unknown, D1 = unknown, D2 = unknown> = SubmitProps<
	T1,
	D1
> &
	Optional<MapProp<ButtonProps, "variant", "secondaryVariant">, "secondaryVariant"> & {
		secondarySubmitter: Submitter<T2, D2>;
		secondaryState?: D2;
	};

export type SomewearDialogProps<T1 = unknown, T2 = unknown, D = unknown> =
	| SubmitProps<T1, D>
	| DualSubmitProps<T1, T2, D>;

function isDualSubmitProps<T1, T2, D>(
	props: SubmitProps<T1, D> | DualSubmitProps<T1, T2, D>,
): props is DualSubmitProps<T1, T2, D> {
	return (props as DualSubmitProps<T1, T2, D>).secondarySubmitter !== undefined;
}

export function SomewearDialog<T1 = unknown, T2 = unknown, D = unknown>(
	props: SubmitProps<T1, D> | DualSubmitProps<T1, T2, D>,
) {
	const { size = "one-col" } = props;
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [showCompletedScreen, setShowCompletedScreen] = useState(false);
	const onCloseSuccess = showCompletedScreen ? true : undefined;

	useEffect(() => {
		if (!props.open) {
			setError(false);
			setErrorMessage("");
			setShowCompletedScreen(false);
		}
	}, [props.open]);

	const onCloseMiddleware: OnCompleteHandler = useCallback(
		(success, response) => {
			if (success === false) {
				setError(true);
				setErrorMessage(response.details);
				return;
			}

			if (props.completedBlock) {
				setError(false);
				setErrorMessage("");
				setShowCompletedScreen(true);
			} else {
				props.onClose(success, response);
			}
		},
		[props, setError],
	);

	const submitButtonRef = createRef<HTMLButtonElement>();

	const handleOpenChange = useCallback(
		(open: boolean) => {
			if (!open) {
				props.onClose();
			}
		},
		[props],
	);

	const primaryButton = !showCompletedScreen ? (
		<PendingButton
			ref={submitButtonRef}
			className={`submit-button`}
			variant={props.primaryVariant}
			validate={props.validate}
			submitter={props.submitter}
			state={props.state}
			submitLabel={props.submitLabel}
			disabled={props.submitDisabled}
			forcePending={props.forceSubmitPending}
			onComplete={onCloseMiddleware}
		/>
	) : (
		<Button variant={props.primaryVariant} onClick={() => props.onClose(onCloseSuccess)}>
			Done
		</Button>
	);

	const secondaryButton = isDualSubmitProps(props) ? (
		<PendingButton
			className={`cancel-button`}
			submitter={props.secondarySubmitter}
			state={props.state}
			submitLabel={props.secondarySubmitLabel ?? "Cancel"}
			onComplete={onCloseMiddleware}
			variant={props.secondaryVariant ?? "outline"}
		/>
	) : (
		<Button
			className={`cancel-button`}
			variant={"outline"}
			onClick={() => {
				props.onClose();
			}}
		>
			{props.secondarySubmitLabel ?? "Cancel"}
		</Button>
	);

	return (
		<Dialog open={props.open} onOpenChange={handleOpenChange}>
			<DialogContent
				className={cn(
					`shadow-none max-h-[90vh] overflow-hidden max-w-[400px] flex-stack`,
					{
						"non-dismissible": props.nonDismissible,
						"max-w-[800px]": size === "two-col",
					},
					props.className,
				)}
			>
				<Container>
					<RenderIf condition={props.title !== undefined}>
						<DialogHeader>
							<DialogTitle>{props.title}</DialogTitle>
						</DialogHeader>
					</RenderIf>

					<DialogMain className={cn(`flex-1`, props.className)}>
						<RenderIf condition={Boolean(error)}>
							<ErrorMessage message={errorMessage} />
						</RenderIf>

						{showCompletedScreen && props.completedBlock
							? props.completedBlock
							: props.children}
					</DialogMain>

					<RenderIf condition={!props.informational}>
						<DialogFooter
							className={cn("items-center", {
								"sm:flex-col-reverse": size === "one-col",
							})}
						>
							<RenderIf condition={!props.nonDismissible && !showCompletedScreen}>
								{secondaryButton}
							</RenderIf>

							{props.footer}

							{primaryButton}
						</DialogFooter>
					</RenderIf>
				</Container>
			</DialogContent>
		</Dialog>
	);
}

type ErrorBlock = { title?: string | React.ReactNode; message?: string };

export const ErrorMessage: React.FC<ErrorBlock> = ({
	title = "An error occurred, please try again.",
	message,
}) => {
	return (
		<div className={`error-block`}>
			<WarningIcon fill={`#fe511a`} />
			<div>
				<div>{title}</div>
				<RenderIf condition={Boolean(message)}>
					<div className={`error-details`}>{message}</div>
				</RenderIf>
			</div>
		</div>
	);
};

const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	width: 100%;

	&::-webkit-scrollbar {
		width: 0.5rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ededed;
		border-radius: 0.25rem;
	}

	.error-block {
		color: ${(props) => props.theme.colors?.alert};
		display: flex;
		align-items: start;
		font-size: 0.875rem;
		line-height: 1.25rem;
		font-weight: 500;
		margin-bottom: 1.5rem;

		svg {
			width: 1rem;
			height: 1rem;
			margin-top: 0.125rem;
			margin-right: 0.5rem;
		}

		.error-details {
			font-size: 0.75rem;
		}
	}

	.submit-button,
	.cancel-button {
		min-width: 9.25rem;
	}

	&.two-col {
		.dialog-content {
			max-width: 100%;
		}
	}
`;
