import type { Header } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import { useState } from "react";
import * as React from "react";

import { RenderIf } from "../../common/RenderIf";
import { cn } from "../../lib/utils";
import { FilterDropdown } from "./FilterDropdown";
import { useSomewearDataTableHeader } from "./SomewearDataTable.hooks";
import { HeaderSortIcon } from "./SomewearDataTableHeaderIcon";

interface IProps<T> {
	header: Header<T, unknown>;
}

export function SomewearDataTableHeader<T>({ header }: IProps<T>) {
	const { canFilter, canSort, selectedFilters, filterableValues } = useSomewearDataTableHeader({
		header,
	});

	const [filtersOpen, setFiltersOpen] = useState(false);

	const handleFilterChange = (values: string[] | undefined) => {
		header.column.setFilterValue(values);
		header.getContext().table.setPageIndex(0);
	};

	return (
		<th
			className={classNames({
				"is-pinned bg-inherit": header.column.getIsPinned(),
				"is-sortable": canSort,
				"is-first-in-group bg-inherit":
					header.column.parent?.columns[0]?.id === header.column.id,
				"is-last-in-group":
					header.column.parent?.columns.slice(-1)[0]?.id === header.column.id,
			})}
			colSpan={header.colSpan}
			style={{ width: header.column.getSize() }}
		>
			<div className={cn("flex items-center")}>
				{header.isPlaceholder
					? null
					: flexRender(header.column.columnDef.header, header.getContext())}

				<RenderIf condition={canFilter}>
					<FilterDropdown
						open={filtersOpen}
						onOpenChange={setFiltersOpen}
						options={filterableValues ?? []}
						selected={selectedFilters}
						onChange={handleFilterChange}
						onSelectAll={() => {
							handleFilterChange(undefined);
							setFiltersOpen(false);
						}}
					/>
				</RenderIf>

				<RenderIf condition={canSort}>
					<HeaderSortIcon
						isSorted={Boolean(header.column.getIsSorted())}
						onClick={header.column.getToggleSortingHandler()}
						sortDirection={header.column.getIsSorted()}
					/>
				</RenderIf>
			</div>
		</th>
	);
}
