import type { Cell } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import * as React from "react";

import { Defer } from "../../components/loading";
import { cn } from "../../lib/utils";

interface IProps<T = unknown> {
	cell: Cell<T, unknown>;
}

export function SomewearDataTableCell<T = unknown>({ cell }: IProps<T>) {
	const defer = cell.column.columnDef.meta?.defer;
	const content = flexRender(cell.column.columnDef.cell, cell.getContext());

	return (
		<td
			className={cn(
				'has-[.has-changes]:bg-ring/15 [&_.has-changes_button:not([role="switch"])]:bg-transparent [&_.has-changes_button:not([role="switch"])]:hover:bg-black/5',
				{
					"is-pinned": cell.column.getIsPinned(),
					"is-first-in-group bg-white":
						cell.column.parent?.columns[0]?.id === cell.column.id,
					"is-last-in-group":
						cell.column.parent?.columns.slice(-1)[0]?.id === cell.column.id,
				},
			)}
			style={{ width: cell.column.getSize() }}
		>
			{defer ? <Defer>{content}</Defer> : content}
		</td>
	);
}
