import * as PopoverPrimitive from "@radix-ui/react-popover";
import { ChevronDown } from "lucide-react";
import * as React from "react";

import { cn } from "../../lib/utils";
import type { ButtonProps } from "./button";
import { Button } from "./button";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverClose = PopoverPrimitive.Close;

const PopoverTriggerButton = (props: ButtonProps) => (
	<PopoverTrigger asChild>
		<Button {...props}>
			<div className="w-full flex items-center justify-between">
				{props.children}
				<ChevronDown className="size-4 flex-shrink-0 translate-x-1 opacity-50" />
			</div>
		</Button>
	</PopoverTrigger>
);

const PopoverContent = React.forwardRef<
	React.ElementRef<typeof PopoverPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
	<PopoverPrimitive.Portal>
		<PopoverPrimitive.Content
			ref={ref}
			align={align}
			sideOffset={sideOffset}
			className={cn(
				"z-50 w-72 border bg-popover p-4 text-popover-foreground rounded overflow-hidden shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 [&_svg]:fill-popover dark:border-none",
				className,
			)}
			{...props}
		/>
	</PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const PopoverArrow = React.forwardRef<
	React.ElementRef<typeof PopoverPrimitive.PopoverArrow>,
	React.ComponentPropsWithoutRef<typeof PopoverPrimitive.PopoverArrow>
>(({ className, ...props }, ref) => (
	<PopoverPrimitive.PopoverArrow ref={ref} className={cn(className)} {...props} />
));
PopoverArrow.displayName = PopoverPrimitive.PopoverArrow.displayName;

export {
	Popover,
	PopoverArrow,
	PopoverClose,
	PopoverContent,
	PopoverTrigger,
	PopoverTriggerButton,
};
