import type {
	AccessorKeyColumnDef,
	FilterFn,
	Header,
	InitialTableState,
	PaginationState,
	TableOptions,
} from "@tanstack/react-table";
import {
	createColumnHelper,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";

import type {
	SomewearColumn,
	SomewearDataTableColumns,
	SomewearDataTableConfigGroup,
	SomewearDataTableConfigList,
} from "./SomewearDataTable.types";
import { deriveDataTableColumns } from "./SomewearDataTable.utils";

interface IProps<T, TColumns> {
	columns: SomewearDataTableColumns<T>;
	data: T[];
	tableGroups: SomewearDataTableConfigGroup<T, TColumns>[];
	listConfig?: SomewearDataTableConfigList<T, TColumns>;
	filterFn?: FilterFn<T>;
	initialState?: InitialTableState;
	pageSize?: number;
	pageIndex?: number;
}

export function useSomewearDataTable<T, TColumns = unknown>(props: IProps<T, TColumns>) {
	const [search, setSearch] = useState("");
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: props.pageIndex ?? 0,
		pageSize: props.pageSize ?? 25,
	});

	const columns = useMemo(
		() => deriveDataTableColumns<T>(props.columns, props.filterFn),
		[props.columns, props.filterFn],
	);

	const columnHelper = createColumnHelper<T>();

	const hiddenColumns = (
		Object.entries(columns) as [string, AccessorKeyColumnDef<T, T[keyof T]>][]
	).filter(([_, col]) => !col.cell);

	const groups = useMemo(() => {
		const visibleGroups = props.tableGroups.map((group) => {
			return columnHelper.group({
				id: group.id,
				header: group.header,
				columns: group.columns.map((key) => columns[key as keyof T]),
				enablePinning: group.sticky,
			});
		});

		if (hiddenColumns.length > 0) {
			return [
				...visibleGroups,
				{ id: "hidden", columns: hiddenColumns.map((col) => col[1]), header: undefined },
			];
		}

		return visibleGroups;
	}, [props.tableGroups, columns, columnHelper, hiddenColumns]);

	const options: TableOptions<T> = {
		data: props.data,
		columns: groups,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onGlobalFilterChange: setSearch,
		initialState: props.initialState ?? {},
		state: {
			globalFilter: search,
			columnPinning: {
				left: (Object.entries(props.columns) as [string, SomewearColumn<T, unknown>][])
					.filter(([_, col]) => col.table?.sticky)
					.map(([key, _]) => key),
			},
			columnVisibility: hiddenColumns.reduce(
				(acc, [key]) => {
					return { ...acc, [key]: false };
				},
				{} as Record<string, boolean>,
			),
		},
		meta: {
			listConfig: props.listConfig,
			isPaginated: Boolean(props.pageSize),
		},
		globalFilterFn: "includesString",
	};

	if (props.pageSize) {
		options.getPaginationRowModel = getPaginationRowModel();
		options.onPaginationChange = setPagination;
		options.autoResetPageIndex = false;

		options.state = {
			...options.state,
			pagination,
		};
	}

	if (props.filterFn) {
		options.filterFns = { filterFn: props.filterFn };
	}

	const table = useReactTable<T>(options);
	(window as any).Table = table;

	useEffect(() => {
		table.setPageIndex(0);
	}, [search]);

	return {
		table,
		search,
		setSearch,
		pagination,
	};
}

export function useSomewearDataTableHeader<T>({ header }: { header: Header<T, unknown> }) {
	const canSort = header.column.getCanSort();
	const isSorted = header.column.getIsSorted();

	const isFilterable = header.column.getCanFilter();
	const filterableValues = useMemo(() => {
		const isFilterable = header.column.getCanFilter();
		if (!isFilterable) return null;

		return getFilterableValues(header);
	}, [header, isFilterable]);

	const canFilter = Boolean(isFilterable && filterableValues && filterableValues.length > 0);
	const isFiltered = header.column.getIsFiltered();
	const selectedFilters = (header.column.getFilterValue() ?? []) as string[];

	return {
		canFilter,
		canSort,
		isSorted,
		isFiltered,
		selectedFilters,
		filterableValues,
	};
}

function getFilterableValues<T>(header: Header<T, unknown>) {
	const accessorFn = header.column.accessorFn;

	return Array.from(
		new Set(
			header
				.getContext()
				.table.getCoreRowModel()
				.rows.map(
					(item, index) =>
						accessorFn?.(item.original, index) ??
						item.original[header.column.id as keyof T],
				)
				.filter(Boolean),
		),
	) as string[];
}
