import * as SwitchPrimitives from "@radix-ui/react-switch";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";

const switchVariants = cva(
	"peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-gray-200",
	{
		variants: {
			variant: {
				default: "h-6 w-11",
				small: "h-5 w-10",
			},
			checkedColor: {
				default: "data-[state=checked]:bg-primary",
				green: "data-[state=checked]:bg-green-300 dark:data-[state=checked]:bg-stealth-green",
			},
			uncheckedColor: {
				default: "data-[state=unchecked]:bg-gray-200",
				dark: "data-[state=unchecked]:bg-gray-400 dark:data-[state=unchecked]:bg-stealth-gray",
			},
		},
		defaultVariants: {
			variant: "default",
			checkedColor: "default",
			uncheckedColor: "default",
		},
	},
);

const switchThumbVariants = cva(
	"pointer-events-none block size rounded-full bg-background shadow-lg ring-0 transition-transform ",
	{
		variants: {
			variant: {
				default:
					"size-5 data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
				small: "size-3.5 data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0.5",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
	checkedColor?: VariantProps<typeof switchVariants>["checkedColor"];
	uncheckedColor?: VariantProps<typeof switchVariants>["uncheckedColor"];
	variant?: VariantProps<typeof switchVariants>["variant"];
}

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
	({ className, checkedColor, uncheckedColor, variant, ...props }, ref) => (
		<SwitchPrimitives.Root
			className={cn(switchVariants({ variant, checkedColor, uncheckedColor, className }))}
			{...props}
			ref={ref}
		>
			<SwitchPrimitives.Thumb className={cn(switchThumbVariants({ variant }))} />
		</SwitchPrimitives.Root>
	),
);
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
