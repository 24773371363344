import * as React from "react";

import { cn } from "../lib/utils";

export function LoadingDots(props: { className?: string; dotClasses?: string }) {
	const dotClasses = cn(`rounded-full size-2 bg-current`, props.dotClasses);

	return (
		<div className="flex justify-center items-center h-8 overflow-hidden">
			<div className={cn("relative flex gap-2", props.className)}>
				<div className={cn(dotClasses, "animate-grow-1")}></div>
				<div className={cn(dotClasses, "animate-grow-2")}></div>
				<div className={cn(dotClasses, "animate-grow-3")}></div>
			</div>
		</div>
	);
}
