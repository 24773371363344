import { FilterList } from "@mui/icons-material";
import type { Header } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import * as React from "react";
import { useCallback, useState } from "react";

import { RenderIf } from "../../common/RenderIf";
import { FilterDropdown } from "./FilterDropdown";
import { useSomewearDataTableHeader } from "./SomewearDataTable.hooks";
import { HeaderSortIcon, SomewearDataTableHeaderIcon } from "./SomewearDataTableHeaderIcon";

export enum SomewearDataListHeaderActions {
	Sort = "sort",
	Filter = "filter",
}

interface IProps<T> {
	header: Header<T, unknown>;
	action: SomewearDataListHeaderActions;
}

export function SomewearDataListHeader<T>({ action, header }: IProps<T>) {
	const { canFilter, canSort, isSorted, isFiltered, selectedFilters, filterableValues } =
		useSomewearDataTableHeader({ header });
	const [filtersOpen, setFiltersOpen] = useState(false);

	const onClick = useCallback(
		(e: any) => {
			if (action === SomewearDataListHeaderActions.Sort) {
				const handler = header.column.getToggleSortingHandler();
				handler?.(e);
			}

			return undefined;
		},
		[action, header.column],
	);

	return (
		<button
			className={classNames("somewear-data-list-header", {
				"is-active": isSorted || isFiltered,
			})}
			id={`somewear-data-list-header-${header.id}`}
			onClick={onClick}
		>
			{flexRender(header.column.columnDef.meta?.list?.header, header.getContext())}

			<RenderIf condition={canFilter && action === SomewearDataListHeaderActions.Filter}>
				<SomewearDataTableHeaderIcon isActive={isFiltered} className="highlight-active">
					{isFiltered ? selectedFilters.length : <FilterList />}
				</SomewearDataTableHeaderIcon>

				<FilterDropdown
					open={filtersOpen}
					onOpenChange={setFiltersOpen}
					options={filterableValues ?? []}
					selected={selectedFilters}
					onChange={(values) => header.column.setFilterValue(values)}
					onSelectAll={() => {
						header.column.setFilterValue(undefined);
						setFiltersOpen(false);
					}}
				/>
			</RenderIf>

			<RenderIf condition={canSort && action === SomewearDataListHeaderActions.Sort}>
				<HeaderSortIcon
					isSorted={Boolean(isSorted)}
					onClick={onClick}
					sortDirection={isSorted}
				/>
			</RenderIf>
		</button>
	);
}
